import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import snctLogo from "../assets/icons/snct-logo.png";
import { constants } from "../constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const drawerWidth = 250;

const ResponsiveDrawer = ({ children }) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabClick = (tabId) => {
    setSelectedSubTab(null);
    setSelectedTab(tabId === selectedTab ? null : tabId);
  };

  const handleSubTabClick = ({ eventId, roundId, tabId, subTabs, level }) => {
    // console.log({ eventId, roundId, tabId, subTabs, level });
    if (subTabs) {
      setSelectedSubTab(tabId === selectedSubTab ? null : tabId);
    } else {
      const queryString = [
        eventId ? `event=${eventId}` : "",
        roundId ? `round=${roundId}` : "",
        level ? `level=${level}` : "",
      ]
        .filter(Boolean)
        .join("&");

      navigate(
        `/competitions/${constants.COMPETITION_ID}${
          queryString ? `?${queryString}` : ""
        }`
      );
      handleDrawerClose();
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar sx={{ justifyContent: "center" }}>
        <Link to="/">
          <img src={snctLogo} width={50} alt="competition logo" />
        </Link>
      </Toolbar>
      <Divider />
      <List>
        {constants.EVENTS.map((event) => (
          <div key={event.id}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleTabClick(event.id)}>
                <ListItemIcon>{event.icon}</ListItemIcon>
                <ListItemText>
                  <Typography variant="subtitle2">{event.label}</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {selectedTab === event.id && (
              <List>
                {event.tabs.map((tab) => (
                  <React.Fragment key={tab.id}>
                    <ListItem
                      disablePadding
                      onClick={() =>
                        handleSubTabClick({
                          eventId: event.eid,
                          roundId: tab.rid,
                          tabId: tab.id,
                          subTabs: tab.tabs ?? false,
                          level: event.level || tab.level,
                        })
                      }
                    >
                      <ListItemButton sx={{ pl: 7 }}>
                        <ListItemText>
                          <Typography variant="subtitle2">
                            {tab.label}
                          </Typography>
                        </ListItemText>
                        {tab.tabs && (
                          <ListItemIcon>
                            <ExpandMoreIcon />
                          </ListItemIcon>
                        )}
                      </ListItemButton>
                    </ListItem>
                    {selectedSubTab === tab.id && (
                      <List>
                        {tab.tabs.map((subTab) => (
                          <ListItem
                            disablePadding
                            key={subTab.id}
                            onClick={() =>
                              handleSubTabClick({
                                eventId: event.eid,
                                roundId: tab.rid,
                                tabId: subTab.id,
                                level: subTab.level,
                              })
                            }
                          >
                            <ListItemButton sx={{ pl: 10 }}>
                              <ListItemText>
                                <Typography variant="subtitle2">
                                  {subTab.label}
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
          </div>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Singapore National Cube Tournament 2024
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box sx={{ width: "100%" }}>{children}</Box>
    </Box>
  );
};

export default ResponsiveDrawer;
