import { createSvgIcon } from "@mui/material";

export const Event2x2x2 = createSvgIcon(
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="47.0588" height="47.0588" rx="5.42857" fill="#00B8D9" />
    <rect
      x="52.9414"
      width="47.0588"
      height="47.0588"
      rx="5.42857"
      fill="#05768A"
    />
    <rect
      y="52.9412"
      width="47.0588"
      height="47.0588"
      rx="5.42857"
      fill="#05768A"
    />
    <rect
      x="52.9414"
      y="52.9412"
      width="47.0588"
      height="47.0588"
      rx="5.42857"
      fill="#00B8D9"
    />
  </svg>,
  "Event2x2x2"
);
