import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "./theme";

const settings = {
  theme: "light",
  responsiveFontSizes: true,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        responsiveFontSizes: settings.responsiveFontSizes,
        mode: settings.theme,
      })}
    >
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
