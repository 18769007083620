import { createSvgIcon } from "@mui/material";

export const EventLevel = createSvgIcon(
  <svg
    width="100"
    height="101"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_574_7818)">
      <rect
        x="35.5967"
        y="35.3845"
        width="28.8462"
        height="384.616"
        fill="#098F16"
      />
      <rect
        x="36.5586"
        y="35.3845"
        width="26.9231"
        height="384.616"
        fill="#41D83D"
      />
      <path
        d="M52.9048 35.3845H63.4817V420.001H52.9048V35.3845Z"
        fill="#06BC18"
      />
      <path
        d="M46.174 35.3845H51.9432V420.001H46.174V35.3845Z"
        fill="#06BC18"
      />
      <path
        d="M43.2894 35.3845H45.2125V420.001H43.2894V35.3845Z"
        fill="#06BC18"
      />
      <path
        d="M36.5586 35.3845H38.4817V420.001H36.5586V35.3845Z"
        fill="#06BC18"
      />
      <path
        d="M39.4432 35.3845H40.4048V420.001H39.4432V35.3845Z"
        fill="#06BC18"
      />
      <path
        d="M62.5201 35.3845H63.4816V420.001H62.5201V35.3845Z"
        fill="#098F16"
      />
      <path
        d="M55.7893 35.3845H56.7508V420.001H55.7893V35.3845Z"
        fill="#098F16"
      />
      <path
        d="M53.8662 35.3845H54.8278V420.001H53.8662V35.3845Z"
        fill="#098F16"
      />
      <path
        d="M57.7124 35.3845H61.5585V420.001H57.7124V35.3845Z"
        fill="#098F16"
      />
    </g>
    <rect x="34.6982" y="20" width="30.7693" height="15.3846" fill="#098F16" />
    <rect
      x="35.6602"
      y="20.9614"
      width="28.8462"
      height="12.5"
      fill="#41D83D"
    />
    <path
      d="M53.9294 21.9231H64.5064V33.4616H53.9294V21.9231Z"
      fill="#06BC18"
    />
    <path
      d="M45.2756 21.9231H52.9679V33.4616H45.2756V21.9231Z"
      fill="#06BC18"
    />
    <path d="M42.3909 21.9231H44.314V33.4616H42.3909V21.9231Z" fill="#06BC18" />
    <path
      d="M35.6602 21.9231H37.5832V33.4616H35.6602V21.9231Z"
      fill="#06BC18"
    />
    <path
      d="M38.5448 21.9231H39.5063V33.4616H38.5448V21.9231Z"
      fill="#06BC18"
    />
    <path d="M63.5445 21.9231H64.506V33.4616H63.5445V21.9231Z" fill="#098F16" />
    <path
      d="M56.8137 21.9231H57.7752V33.4616H56.8137V21.9231Z"
      fill="#098F16"
    />
    <path
      d="M54.8906 21.9231H55.8522V33.4616H54.8906V21.9231Z"
      fill="#098F16"
    />
    <path
      d="M58.7368 21.9231H62.5829V33.4616H58.7368V21.9231Z"
      fill="#098F16"
    />
    <g clipPath="url(#clip1_574_7818)">
      <rect
        x="1.59668"
        y="65.3845"
        width="28.8462"
        height="384.616"
        fill="#098F16"
      />
      <rect
        x="2.55859"
        y="65.3845"
        width="26.9231"
        height="384.616"
        fill="#41D83D"
      />
      <path
        d="M18.9048 65.3845H29.4817V450.001H18.9048V65.3845Z"
        fill="#06BC18"
      />
      <path
        d="M12.174 65.3845H17.9432V450.001H12.174V65.3845Z"
        fill="#06BC18"
      />
      <path
        d="M9.28938 65.3845H11.2125V450.001H9.28938V65.3845Z"
        fill="#06BC18"
      />
      <path
        d="M2.55859 65.3845H4.48167V450.001H2.55859V65.3845Z"
        fill="#06BC18"
      />
      <path
        d="M5.44321 65.3845H6.40475V450.001H5.44321V65.3845Z"
        fill="#06BC18"
      />
      <path
        d="M28.5201 65.3845H29.4816V450.001H28.5201V65.3845Z"
        fill="#098F16"
      />
      <path
        d="M21.7893 65.3845H22.7508V450.001H21.7893V65.3845Z"
        fill="#098F16"
      />
      <path
        d="M19.8662 65.3845H20.8278V450.001H19.8662V65.3845Z"
        fill="#098F16"
      />
      <path
        d="M23.7124 65.3845H27.5585V450.001H23.7124V65.3845Z"
        fill="#098F16"
      />
    </g>
    <rect x="0.698242" y="50" width="30.7693" height="15.3846" fill="#098F16" />
    <rect
      x="1.66016"
      y="50.9614"
      width="28.8462"
      height="12.5"
      fill="#41D83D"
    />
    <path
      d="M19.9294 51.9231H30.5064V63.4616H19.9294V51.9231Z"
      fill="#06BC18"
    />
    <path
      d="M11.2756 51.9231H18.9679V63.4616H11.2756V51.9231Z"
      fill="#06BC18"
    />
    <path d="M8.39094 51.9231H10.314V63.4616H8.39094V51.9231Z" fill="#06BC18" />
    <path
      d="M1.66016 51.9231H3.58324V63.4616H1.66016V51.9231Z"
      fill="#06BC18"
    />
    <path
      d="M4.54478 51.9231H5.50632V63.4616H4.54478V51.9231Z"
      fill="#06BC18"
    />
    <path d="M29.5445 51.9231H30.506V63.4616H29.5445V51.9231Z" fill="#098F16" />
    <path
      d="M22.8137 51.9231H23.7752V63.4616H22.8137V51.9231Z"
      fill="#098F16"
    />
    <path
      d="M20.8906 51.9231H21.8522V63.4616H20.8906V51.9231Z"
      fill="#098F16"
    />
    <path
      d="M24.7368 51.9231H28.5829V63.4616H24.7368V51.9231Z"
      fill="#098F16"
    />
    <g clipPath="url(#clip2_574_7818)">
      <rect
        x="69.5967"
        y="15.3845"
        width="28.8462"
        height="384.616"
        fill="#098F16"
      />
      <rect
        x="70.5586"
        y="15.3845"
        width="26.9231"
        height="384.616"
        fill="#41D83D"
      />
      <path
        d="M86.9048 15.3845H97.4817V400.001H86.9048V15.3845Z"
        fill="#06BC18"
      />
      <path
        d="M80.174 15.3845H85.9432V400.001H80.174V15.3845Z"
        fill="#06BC18"
      />
      <path
        d="M77.2894 15.3845H79.2125V400.001H77.2894V15.3845Z"
        fill="#06BC18"
      />
      <path
        d="M70.5586 15.3845H72.4817V400.001H70.5586V15.3845Z"
        fill="#06BC18"
      />
      <path
        d="M73.4432 15.3845H74.4048V400.001H73.4432V15.3845Z"
        fill="#06BC18"
      />
      <path
        d="M96.5201 15.3845H97.4816V400.001H96.5201V15.3845Z"
        fill="#098F16"
      />
      <path
        d="M89.7893 15.3845H90.7508V400.001H89.7893V15.3845Z"
        fill="#098F16"
      />
      <path
        d="M87.8662 15.3845H88.8278V400.001H87.8662V15.3845Z"
        fill="#098F16"
      />
      <path
        d="M91.7124 15.3845H95.5585V400.001H91.7124V15.3845Z"
        fill="#098F16"
      />
    </g>
    <rect x="69" width="30.9304" height="15.3846" fill="#098F16" />
    <rect
      x="69.9668"
      y="0.961426"
      width="28.9972"
      height="12.5"
      fill="#41D83D"
    />
    <path d="M88.3317 1.9231H98.964V13.4616H88.3317V1.9231Z" fill="#06BC18" />
    <path d="M79.6325 1.9231H87.3651V13.4616H79.6325V1.9231Z" fill="#06BC18" />
    <path d="M76.7328 1.9231H78.666V13.4616H76.7328V1.9231Z" fill="#06BC18" />
    <path d="M69.9668 1.9231H71.8999V13.4616H69.9668V1.9231Z" fill="#06BC18" />
    <path d="M72.8665 1.9231H73.8331V13.4616H72.8665V1.9231Z" fill="#06BC18" />
    <path d="M97.997 1.9231H98.9636V13.4616H97.997V1.9231Z" fill="#098F16" />
    <path d="M91.231 1.9231H92.1976V13.4616H91.231V1.9231Z" fill="#098F16" />
    <path d="M89.2979 1.9231H90.2644V13.4616H89.2979V1.9231Z" fill="#098F16" />
    <path d="M93.1641 1.9231H97.0304V13.4616H93.1641V1.9231Z" fill="#098F16" />
    <defs>
      <clipPath id="clip0_574_7818">
        <rect
          width="28.8462"
          height="64.6286"
          fill="white"
          transform="translate(35.6602 35.3845)"
        />
      </clipPath>
      <clipPath id="clip1_574_7818">
        <rect
          width="28.8462"
          height="34.6286"
          fill="white"
          transform="translate(1.66016 65.3845)"
        />
      </clipPath>
      <clipPath id="clip2_574_7818">
        <rect
          width="28.8462"
          height="84.5192"
          fill="white"
          transform="translate(69.6602 15.3845)"
        />
      </clipPath>
    </defs>
  </svg>,
  "EventLevel"
);
