import { createSvgIcon } from "@mui/material";

export const EventIndividual = createSvgIcon(
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="87.8271"
      y="57.5901"
      width="3.21355"
      height="6.42727"
      fill="#313148"
    />
    <rect
      x="72.5654"
      y="70.4453"
      width="12.8542"
      height="12.0511"
      fill="#666AB0"
    />
    <rect
      x="85.4199"
      y="73.6587"
      width="3.21355"
      height="5.62386"
      fill="#666AB0"
    />
    <rect
      x="59.7119"
      y="79.2839"
      width="3.21355"
      height="6.42727"
      fill="#666AB0"
    />
    <rect
      x="72.5654"
      y="35.8982"
      width="18.4779"
      height="21.692"
      fill="#FFE2C0"
    />
    <rect
      x="69.3535"
      y="60.8052"
      width="19.2813"
      height="9.6409"
      fill="#FFE2C0"
    />
    <rect
      x="75.7812"
      y="23.8474"
      width="9.64065"
      height="3.21363"
      fill="#313148"
    />
    <rect
      x="72.5654"
      y="27.0608"
      width="18.4779"
      height="3.21363"
      fill="#313148"
    />
    <rect
      x="69.3535"
      y="29.4734"
      width="24.905"
      height="3.21363"
      fill="#313148"
    />
    <rect
      x="66.1377"
      y="32.6846"
      width="28.1186"
      height="3.21363"
      fill="#313148"
    />
    <rect
      x="66.1377"
      y="35.8999"
      width="6.4271"
      height="3.21363"
      fill="#313148"
    />
    <rect
      x="66.1377"
      y="39.1135"
      width="3.21355"
      height="21.692"
      fill="#313148"
    />
    <rect
      x="72.5654"
      y="42.3269"
      width="3.21355"
      height="6.42727"
      fill="#3B362F"
    />
    <rect
      x="85.4199"
      y="42.3269"
      width="3.21355"
      height="6.42727"
      fill="#3B362F"
    />
    <rect
      x="75.7812"
      y="48.7539"
      width="9.64065"
      height="6.42727"
      fill="#371112"
    />
    <rect
      x="69.3535"
      y="39.1116"
      width="3.21355"
      height="18.4784"
      fill="#F5D2B0"
    />
    <rect
      x="75.7812"
      y="57.5913"
      width="6.4271"
      height="3.21363"
      fill="#F5D2B0"
    />
    <rect
      x="72.5654"
      y="57.5913"
      width="3.21355"
      height="3.21363"
      fill="#5A5FAB"
    />
    <rect
      x="66.1377"
      y="70.4453"
      width="3.21355"
      height="6.42727"
      fill="#5A5FAB"
    />
    <rect
      x="69.3535"
      y="73.6592"
      width="3.21355"
      height="8.8375"
      fill="#5A5FAB"
    />
    <rect
      x="72.5654"
      y="70.4453"
      width="3.21355"
      height="3.21363"
      fill="#5A5FAB"
    />
    <rect
      x="72.5654"
      y="79.2837"
      width="3.21355"
      height="3.21363"
      fill="#5A5FAB"
    />
    <rect
      x="75.7812"
      y="60.8047"
      width="6.4271"
      height="9.6409"
      fill="#5A5FAB"
    />
    <rect
      x="82.207"
      y="88.9248"
      width="6.4271"
      height="3.21363"
      fill="#5A5FAB"
    />
    <rect
      x="69.3535"
      y="57.5916"
      width="3.21355"
      height="3.21363"
      fill="#D87373"
    />
    <rect
      x="66.1377"
      y="76.0703"
      width="3.21355"
      height="3.21363"
      fill="#D87373"
    />
    <rect
      x="66.1377"
      y="60.8047"
      width="3.21355"
      height="9.6409"
      fill="#D87373"
    />
    <rect
      x="62.9248"
      y="67.2317"
      width="3.21355"
      height="12.0511"
      fill="#D87373"
    />
    <rect
      x="59.7119"
      y="67.2322"
      width="3.21355"
      height="8.8375"
      fill="#F1908F"
    />
    <rect
      x="62.9248"
      y="57.5913"
      width="3.21355"
      height="9.6409"
      fill="#F1908F"
    />
    <rect
      x="72.5654"
      y="60.8047"
      width="3.21355"
      height="3.21363"
      fill="#D87373"
    />
    <rect
      x="69.3535"
      y="70.4456"
      width="3.21355"
      height="3.21363"
      fill="#D87373"
    />
    <rect
      x="82.207"
      y="57.5916"
      width="3.21355"
      height="6.42727"
      fill="#D87373"
    />
    <rect
      x="91.04"
      y="35.8994"
      width="3.21355"
      height="21.692"
      fill="#313148"
    />
    <rect
      x="69.3535"
      y="14.207"
      width="9.64065"
      height="3.21363"
      fill="#E98380"
    />
    <rect
      x="56.499"
      y="23.0442"
      width="9.64065"
      height="3.21363"
      fill="#D2716A"
    />
    <rect
      x="72.5654"
      y="20.6338"
      width="3.21355"
      height="3.21363"
      fill="#D2716A"
    />
    <rect
      x="56.499"
      y="26.2576"
      width="4.01694"
      height="3.21363"
      fill="#D2716A"
    />
    <rect
      x="62.9248"
      y="17.4204"
      width="3.21355"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="72.5654"
      y="23.8474"
      width="3.21355"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="87.8271"
      y="35.8994"
      width="3.21355"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="72.5654"
      y="32.687"
      width="16.0677"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="66.1377"
      y="27.0608"
      width="6.4271"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="66.1377"
      y="29.4712"
      width="3.21355"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="62.9248"
      y="32.6846"
      width="3.21355"
      height="21.692"
      fill="#2A2A3F"
    />
    <rect
      x="57.3018"
      y="17.4199"
      width="3.21355"
      height="5.62386"
      fill="#2A2A3F"
    />
    <rect
      x="50.874"
      y="23.8467"
      width="3.21355"
      height="5.62386"
      fill="#2A2A3F"
    />
    <rect
      x="47.6611"
      y="26.2568"
      width="3.21355"
      height="3.21363"
      fill="#2A2A3F"
    />
    <rect
      x="62.9248"
      y="20.6338"
      width="3.21355"
      height="2.41023"
      fill="#313148"
    />
    <rect
      x="59.7119"
      y="17.4204"
      width="3.21355"
      height="5.62386"
      fill="#313148"
    />
    <rect
      x="50.874"
      y="23.8467"
      width="5.62371"
      height="8.8375"
      fill="#313148"
    />
    <rect
      x="60.5156"
      y="26.2568"
      width="5.62371"
      height="3.21363"
      fill="#E98380"
    />
    <rect
      x="54.0869"
      y="29.4705"
      width="9.64065"
      height="3.21363"
      fill="#E98380"
    />
    <rect
      x="66.1377"
      y="17.4204"
      width="9.64065"
      height="3.21363"
      fill="#E98380"
    />
    <rect
      x="66.1377"
      y="20.6338"
      width="6.4271"
      height="6.42727"
      fill="#E98380"
    />
    <rect
      x="77.3828"
      y="51.968"
      width="6.4271"
      height="3.21363"
      fill="#C53D28"
    />
    <rect
      x="62.9248"
      y="79.2837"
      width="6.4271"
      height="3.21363"
      fill="#FFE2C0"
    />
    <rect
      x="82.207"
      y="82.4978"
      width="3.21355"
      height="6.42727"
      fill="#F5D2B0"
    />
    <rect
      x="17.9375"
      y="33.3501"
      width="26.5118"
      height="23.2989"
      fill="#FFE2C0"
    />
    <rect
      x="25.1709"
      y="39.7803"
      width="3.21355"
      height="6.42727"
      fill="#3B362F"
    />
    <rect
      x="38.0225"
      y="39.7803"
      width="3.21355"
      height="6.42727"
      fill="#3B362F"
    />
    <rect
      x="27.5781"
      y="46.2065"
      width="9.64065"
      height="4.01704"
      fill="#371112"
    />
    <rect
      x="27.5781"
      y="50.2236"
      width="6.4271"
      height="3.21363"
      fill="#371112"
    />
    <rect
      x="27.5781"
      y="50.2236"
      width="3.21355"
      height="3.21363"
      fill="#C53D28"
    />
    <rect
      x="14.7236"
      y="26.9233"
      width="3.21355"
      height="16.0682"
      fill="#101111"
    />
    <rect
      x="11.5088"
      y="26.9231"
      width="3.21355"
      height="12.8545"
      fill="#101111"
    />
    <rect
      x="8.2959"
      y="33.3525"
      width="3.21355"
      height="6.42727"
      fill="#101111"
    />
    <rect
      x="14.7236"
      y="23.71"
      width="7.23049"
      height="12.8545"
      fill="#101111"
    />
    <rect
      x="41.2373"
      y="20.4971"
      width="3.21355"
      height="16.0682"
      fill="#3B3B3B"
    />
    <rect
      x="38.0225"
      y="20.4966"
      width="3.21355"
      height="12.8545"
      fill="#212121"
    />
    <rect
      x="34.8096"
      y="20.4966"
      width="3.21355"
      height="12.8545"
      fill="#3B3B3B"
    />
    <rect
      x="38.0225"
      y="17.2832"
      width="3.21355"
      height="3.21363"
      fill="#3B3B3B"
    />
    <rect
      x="34.8096"
      y="17.2832"
      width="3.21355"
      height="3.21363"
      fill="#212121"
    />
    <rect
      x="14.7236"
      y="42.9932"
      width="3.21355"
      height="9.6409"
      fill="#F4CFAD"
    />
    <rect
      x="17.9375"
      y="52.6335"
      width="3.21355"
      height="3.21363"
      fill="#F4CFAD"
    />
    <rect
      x="14.7236"
      y="52.6335"
      width="3.21355"
      height="10.4443"
      fill="#666AB0"
    />
    <rect
      x="8.2959"
      y="63.0771"
      width="16.8711"
      height="12.8545"
      fill="#5F64AD"
    />
    <rect
      x="8.2959"
      y="52.6335"
      width="3.21355"
      height="10.4443"
      fill="#666AB0"
    />
    <rect
      x="5.08301"
      y="62.2739"
      width="3.21355"
      height="10.4443"
      fill="#8C8EC5"
    />
    <rect
      x="11.5088"
      y="52.6335"
      width="3.21355"
      height="10.4443"
      fill="#4C52A3"
    />
    <rect
      x="34.8096"
      y="55.8477"
      width="3.21355"
      height="17.675"
      fill="#666AB0"
    />
    <rect
      x="17.9375"
      y="55.8469"
      width="7.23049"
      height="7.23068"
      fill="#666AB0"
    />
    <rect
      x="8.2959"
      y="39.7795"
      width="6.4271"
      height="10.4443"
      fill="#FFE2C0"
    />
    <rect
      x="44.4512"
      y="42.9937"
      width="3.21355"
      height="9.6409"
      fill="#FFE2C0"
    />
    <rect
      x="28.3838"
      y="17.2834"
      width="3.21355"
      height="3.21363"
      fill="#212121"
    />
    <rect
      x="17.9375"
      y="59.8638"
      width="3.21355"
      height="3.21363"
      fill="#F05758"
    />
    <rect
      x="38.0225"
      y="59.8643"
      width="3.21355"
      height="3.21363"
      fill="#F05758"
    />
    <rect
      x="25.1709"
      y="55.8477"
      width="6.4271"
      height="16.8716"
      fill="#F05758"
    />
    <rect
      x="31.5967"
      y="59.8645"
      width="3.21355"
      height="12.8545"
      fill="#EB5151"
    />
    <rect
      x="17.9375"
      y="63.0774"
      width="7.23049"
      height="6.42727"
      fill="#FFE2C0"
    />
    <rect
      x="27.5781"
      y="55.8472"
      width="7.23049"
      height="4.01704"
      fill="#F4CFAD"
    />
    <rect
      x="34.8096"
      y="62.2747"
      width="7.23049"
      height="6.42727"
      fill="#FFE2C0"
    />
    <rect
      x="21.9551"
      y="42.9937"
      width="3.21355"
      height="3.21363"
      fill="#F48A92"
    />
    <rect
      x="41.2373"
      y="42.9939"
      width="3.21355"
      height="3.21363"
      fill="#F48A92"
    />
    <rect
      x="31.5967"
      y="17.2834"
      width="3.21355"
      height="3.21363"
      fill="#3B3B3B"
    />
    <rect
      x="31.5967"
      y="20.4968"
      width="3.21355"
      height="12.8545"
      fill="#212121"
    />
    <rect
      x="28.3838"
      y="20.4971"
      width="3.21355"
      height="12.8545"
      fill="#3B3B3B"
    />
    <rect
      x="34.8096"
      y="72.7183"
      width="3.21355"
      height="6.42727"
      fill="#605E64"
    />
    <rect
      x="34.8096"
      y="88.7871"
      width="6.4271"
      height="3.21363"
      fill="#4D4D52"
    />
    <rect
      x="15.5293"
      y="88.7876"
      width="6.4271"
      height="3.21363"
      fill="#605E64"
    />
    <rect
      x="21.9551"
      y="75.9319"
      width="3.21355"
      height="3.21363"
      fill="#605E64"
    />
    <rect
      x="25.1709"
      y="72.7183"
      width="3.21355"
      height="3.21363"
      fill="#605E64"
    />
    <rect
      x="28.3838"
      y="72.7188"
      width="6.4271"
      height="3.21363"
      fill="#535157"
    />
    <rect
      x="34.8096"
      y="79.1455"
      width="3.21355"
      height="9.6409"
      fill="#F1CEAD"
    />
    <rect
      x="18.7422"
      y="82.3606"
      width="3.21355"
      height="6.42727"
      fill="#FFE2C0"
    />
    <rect
      x="21.9551"
      y="79.1472"
      width="3.21355"
      height="3.21363"
      fill="#FFE2C0"
    />
    <rect
      x="21.9551"
      y="20.4966"
      width="6.4271"
      height="12.8545"
      fill="#212121"
    />
    <rect
      x="18.7422"
      y="26.9238"
      width="3.21355"
      height="9.6409"
      fill="#212121"
    />
    <rect
      x="11.5088"
      y="42.9924"
      width="3.21355"
      height="3.21363"
      fill="#EBC39B"
    />
  </svg>,
  "EventIndividual"
);
