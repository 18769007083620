import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import { Box } from "@mui/material";
import Event from "./pages/Event";
import DialogWelcome from "./components/dialogs/dialog-welcome";
import { useState } from "react";

function App() {
  const showDialog = localStorage.getItem("welcome_dialog");
  const [open, setOpen] = useState(!showDialog && true);

  const handleClose = () => {
    localStorage.setItem("welcome_dialog", false);
    setOpen(false);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: { xs: 10, md: 14 },
        px: { xs: 2, md: 6 },
      }}
    >
      <DialogWelcome open={open} onClose={handleClose} />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path={`/competitions/:id`} element={<Event />} />
      </Routes>
    </Box>
  );
}

export default App;
