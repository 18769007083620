import { createSvgIcon } from "@mui/material";

export const Event4x4x4 = createSvgIcon(
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22.2477" height="22.2477" rx="2.75229" fill="#056142" />
    <rect
      x="25.917"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      x="51.835"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      x="77.752"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
    <rect
      y="25.9175"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      x="25.917"
      y="25.9175"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
    <rect
      x="51.835"
      y="25.9175"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
    <rect
      x="77.752"
      y="25.9175"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      y="51.835"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      x="25.917"
      y="51.835"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
    <rect
      x="51.835"
      y="51.835"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
    <rect
      x="77.752"
      y="51.835"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      y="77.7522"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
    <rect
      x="25.917"
      y="77.7522"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      x="51.835"
      y="77.7522"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#00A76F"
    />
    <rect
      x="77.752"
      y="77.7522"
      width="22.2477"
      height="22.2477"
      rx="2.75229"
      fill="#056142"
    />
  </svg>,
  "Event4x4x4"
);
