import { useTheme } from "@emotion/react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatPoints, formatTiming } from "../../utils";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
const TableSchool = ({
  school,
  isMobile,
  top,
  handleClickOpen,
  isOverall,
  isQualified,
  index,
}) => {
  // console.log(school);
  // school.sort((a, b) => b.points - a.points);
  const theme = useTheme();
  return (
    <Table aria-label="simple table" sx>
      <TableHead>
        <TableRow>
          <TableCell colSpan={isMobile ? 2 : 7} align="left">
            {`#${index + 1} ${school?.school}`}{" "}
            {isQualified && (
              <KeyboardDoubleArrowUpIcon
                sx={{ fontSize: 15, fill: theme.palette.secondary.main }}
              />
            )}
          </TableCell>
          {/* {!isMobile && <TableCell colSpan={9} />} */}
          <TableCell colSpan={isMobile ? 2 : 4} align="right">
            Total Score: {formatPoints(school?.totalPoints)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">#</TableCell>
          <TableCell align="left">Name</TableCell>
          {isMobile ? null : (
            <>
              <TableCell align="left">Age</TableCell>
              <TableCell align="left">School</TableCell>
              <TableCell align="center">1</TableCell>
              <TableCell align="center">2</TableCell>
              <TableCell align="center">3</TableCell>
              <TableCell align="center">4</TableCell>
              <TableCell align="center">5</TableCell>
            </>
          )}
          <TableCell align="center">Average</TableCell>
          <TableCell align="center">Points</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {school?.participants?.map((row) =>
          (isOverall && row.positionInSchool <= 3) || !isOverall ? (
            <TableRow
              hover
              onClick={() => handleClickOpen(row)}
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                align="center"
                component="th"
                scope="row"
                style={
                  row?.positionInSchool <= top
                    ? {
                        background: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                      }
                    : {}
                }
              >
                <Typography variant="caption2">
                  {row?.positionInSchool}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="caption2">{row?.name}</Typography>
              </TableCell>
              {isMobile ? null : (
                <>
                  <TableCell align="left">
                    <Typography variant="caption2">{row?.age}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="caption2">{row?.school}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption2">
                      {formatTiming(row?.timing_1)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption2">
                      {formatTiming(row?.timing_2)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption2">
                      {formatTiming(row?.timing_3)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption2">
                      {formatTiming(row?.timing_4)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption2">
                      {formatTiming(row?.timing_5)}
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell align="center">
                <Typography variant="caption2">
                  {formatTiming(row.timing_average)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="caption2">
                  {formatPoints(row.championship_points)}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null
        )}
      </TableBody>
    </Table>
  );
};

export default TableSchool;
