import { createSvgIcon } from "@mui/material";

export const Event3x3x3 = createSvgIcon(
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30.031" height="30.031" rx="3.71517" fill="#4B2800" />
    <rect
      x="34.9844"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#FFAB00"
    />
    <rect
      x="69.9688"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#4B2800"
    />
    <rect
      y="34.9846"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#FFAB00"
    />
    <rect
      x="34.9844"
      y="34.9846"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#4B2800"
    />
    <rect
      x="69.9688"
      y="34.9846"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#FFAB00"
    />
    <rect
      y="69.969"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#4B2800"
    />
    <rect
      x="34.9844"
      y="69.969"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#FFAB00"
    />
    <rect
      x="69.9688"
      y="69.969"
      width="30.031"
      height="30.031"
      rx="3.71517"
      fill="#4B2800"
    />
  </svg>,
  "Event3x3x3"
);
