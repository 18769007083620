import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  alpha,
} from "@mui/material";
import { format, addHours, subHours } from "date-fns";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { constants } from "../../constants";

const eventHasStarted = (startTime, endTime) => {
  return new Date() >= startTime && new Date() <= endTime;
};

const EventsList = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      {constants.TIMINGS.map((event) => (
        <Grid item xl={4} md={4} xs={12} key={event.id}>
          <Link
            to={`/competitions/${constants.COMPETITION_ID}?event=${
              event.eid
            }&round=${event.rid}${
              event.levelId ? `&level=${event.levelId}` : ""
            }`}
            style={{ textDecoration: "none" }}
          >
            <Card
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.neutral[100],
                  cursor: "pointer",
                },
              }}
            >
              <CardContent>
                <Box display="flex" gap={2} alignItems="center">
                  <div>{event.icon}</div>
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {`${event.event} Cube - ${
                      event.rid !== "snct_school_qual" ? "Round" : ""
                    } ${event.round}`}
                  </Typography>
                </Box>
                <Box display="flex" gap={2} pt={3} alignItems="center">
                  {eventHasStarted(event.startTime, event.endTime) && (
                    <Box
                      sx={{
                        animation: "pulse ease 750ms infinite",
                        borderRadius: "50%",
                        p: 0.5,
                        "@keyframes pulse": {
                          "0%": {
                            boxShadow: "none",
                          },
                          "100%": {
                            boxShadow: (theme) =>
                              `0px 0px 0px 6px ${alpha(
                                theme.palette.secondary.main,
                                0.1
                              )}`,
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "secondary.main",
                          borderRadius: "50%",
                          height: 12,
                          width: 12,
                        }}
                      />
                    </Box>
                  )}
                  {!eventHasStarted(event.startTime, event.endTime) && (
                    <Box
                      sx={{
                        backgroundColor: "error.main",
                        borderRadius: "50%",
                        height: 12,
                        width: 12,
                      }}
                    />
                  )}
                  <Typography variant="caption">
                    {format(event.startTime, "hh:mm a")} -{" "}
                    {format(event.endTime, "hh:mm a")}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default EventsList;
