import { Event2x2x2 } from "./assets/icons/event-2x2x2";
import { Event3x3x3 } from "./assets/icons/event-3x3x3";
import { Event4x4x4 } from "./assets/icons/event-4x4x4";
import { EventIndividual } from "./assets/icons/event-individual";
import { EventLevel } from "./assets/icons/event-level";
import { EventPyraminx } from "./assets/icons/event-pyraminx";
import { EventSchool } from "./assets/icons/event-school";

export const constants = {
  COMPETITION_ID: "snct_2024_0310",
  EVENTS: [
    {
      id: 1,
      eid: "snct_2x2x2",
      label: "2x2x2 Cube",
      level: "overall",
      tabs: [
        {
          id: 1,
          label: "Round 1",
          rid: "snct_2x2x2_round1",
          round: 1,
          top: 12,
          isFinalRound: false,
          startTime: new Date("2024-03-10T14:00:00"),
          endTime: new Date("2024-03-10T14:45:00"),
        },
        {
          id: 2,
          label: "Round 2",
          rid: "snct_2x2x2_round2",
          round: 2,
          top: 3,
          isFinalRound: true,
          startTime: new Date("2024-03-10T16:10:00"),
          endTime: new Date("2024-03-10T16:30:00"),
        },
      ],
      icon: <Event2x2x2 />,
    },
    {
      id: 2,
      eid: "snct_3x3x3",
      label: "3x3x3 Cube",
      level: "overall",
      tabs: [
        {
          id: 1,
          label: "Round 1",
          rid: "snct_3x3x3_round1",
          round: 1,
          top: 60,
          isFinalRound: false,
          startTime: new Date("2024-03-10T10:30:00"),
          endTime: new Date("2024-03-10T12:30:00"),
        },
        {
          id: 2,
          label: "Round 2",
          rid: "snct_3x3x3_round2",
          round: 2,
          top: 12,
          isFinalRound: false,
          startTime: new Date("2024-03-10T15:30:00"),
          endTime: new Date("2024-03-10T15:50:00"),
        },
        {
          id: 3,
          label: "Round 3",
          rid: "snct_3x3x3_round3",
          round: 3,
          top: 3,
          isFinalRound: true,
          startTime: new Date("2024-03-10T16:50:00"),
          endTime: new Date("2024-03-10T17:50:00"),
        },
      ],
      icon: <Event3x3x3 />,
    },
    {
      id: 3,
      eid: "snct_4x4x4",
      label: "4x4x4 Cube",
      level: "overall",
      tabs: [
        {
          id: 1,
          label: "Round 1",
          rid: "snct_4x4x4_round1",
          round: 1,
          top: 12,
          isFinalRound: false,
          startTime: new Date("2024-03-10T09:00:00"),
          endTime: new Date("2024-03-10T10:30:00"),
        },
        {
          id: 2,
          label: "Round 2",
          rid: "snct_4x4x4_round2",
          round: 2,
          top: 3,
          isFinalRound: true,
          startTime: new Date("2024-03-10T15:50:00"),
          endTime: new Date("2024-03-10T16:10:00"),
        },
      ],
      icon: <Event4x4x4 />,
    },
    {
      id: 4,
      eid: "snct_pyraminx",
      label: "Pyraminx Cube",
      level: "overall",
      tabs: [
        {
          id: 1,
          label: "Round 1",
          rid: "snct_pyraminx_round1",
          round: 1,
          top: 12,
          isFinalRound: false,
          startTime: new Date("2024-03-10T14:45:00"),
          endTime: new Date("2024-03-10T15:30:00"),
        },
        {
          id: 2,
          label: "Round 2",
          rid: "snct_pyraminx_round2",
          event: "pyraminx",
          round: 2,
          top: 3,
          isFinalRound: true,
          startTime: new Date("2024-03-10T16:30:00"),
          endTime: new Date("2024-03-10T16:50:00"),
        },
      ],
      icon: <EventPyraminx />,
    },
    {
      id: 5,
      eid: "snct_level",
      label: "Level Category",
      tabs: [
        {
          id: 1,
          top: 3,
          rid: "snct_2x2x2_round1",
          label: "2x2x2 Cube",
          tabs: [
            {
              id: 1,
              label: "Lower Primary",
              level: "lower primary",
            },
            { id: 2, label: "Upper Primary", level: "upper primary" },
            { id: 3, label: "Primary (Overall)", level: "primary" },
            { id: 4, label: "Lower Secondary", level: "lower secondary" },
            { id: 5, label: "Upper Secondary", level: "upper secondary" },
            { id: 6, label: "Secondary (Overall)", level: "secondary" },
          ],
        },
        {
          id: 2,
          top: 3,
          rid: "snct_3x3x3_round1",
          label: "3x3x3 Cube",
          tabs: [
            { id: 1, label: "Lower Primary", level: "lower primary" },
            { id: 2, label: "Upper Primary", level: "upper primary" },
            { id: 3, label: "Primary (Overall)", level: "primary" },
            { id: 4, label: "Lower Secondary", level: "lower secondary" },
            { id: 5, label: "Upper Secondary", level: "upper secondary" },
            { id: 6, label: "Secondary (Overall)", level: "secondary" },
          ],
        },
        {
          id: 3,
          top: 3,
          rid: "snct_4x4x4_round1",
          label: "4x4x4 Cube",
          tabs: [
            { id: 1, label: "Lower Primary", level: "lower primary" },
            { id: 2, label: "Upper Primary", level: "upper primary" },
            { id: 3, label: "Primary (Overall)", level: "primary" },
            { id: 4, label: "Lower Secondary", level: "lower secondary" },
            { id: 5, label: "Upper Secondary", level: "upper secondary" },
            { id: 6, label: "Secondary (Overall)", level: "secondary" },
          ],
        },
        {
          id: 4,
          top: 3,
          rid: "snct_pyraminx_round1",
          label: "Pyraminx Cube",
          tabs: [
            { id: 1, label: "Lower Primary", level: "lower primary" },
            { id: 2, label: "Upper Primary", level: "upper primary" },
            { id: 3, label: "Primary (Overall)", level: "primary" },
            { id: 4, label: "Lower Secondary", level: "lower secondary" },
            { id: 5, label: "Upper Secondary", level: "upper secondary" },
            { id: 6, label: "Secondary (Overall)", level: "secondary" },
          ],
        },
      ],
      icon: <EventLevel />,
    },
    {
      id: 6,
      label: "Individual Category",
      top: 3,
      tabs: [
        {
          id: 1,
          label: "Primary",
          level: "primary",
        },
        {
          id: 2,
          label: "Secondary",
          level: "secondary",
        },
        {
          id: 3,
          label: "Overall",
          level: "overall",
        },
      ],
      icon: <EventIndividual />,
    },
    {
      id: 7,
      eid: "snct_school",
      label: "School Category",
      tabs: [
        {
          id: 1,
          label: "Qualifying",
          rid: "snct_school_qual",
          top: 3,
          startTime: new Date("2024-03-10T14:45:00"),
          endTime: new Date("2024-03-10T15:30:00"),
        },
        {
          id: 2,
          label: "Overall",
          rid: "snct_school_ovr",
          top: 3,
          startTime: new Date("2024-03-10T16:30:00"),
          endTime: new Date("2024-03-10T16:50:00"),
        },
      ],
      icon: <EventSchool />,
    },
  ],
  LABELS: {
    CATEGORY: {
      snct_2x2x2_round1: {
        title: "2x2x2 Cube Round 1",
        subtitle:
          "Top 12 Participants will be proceeding to Round 2. Do take note of your position and report for Round 2 (Final) at 4:10 PM",
      },
      snct_2x2x2_round2: { title: "2x2x2 Cube Round 2" },
      snct_3x3x3_round1: {
        title: "3x3x3 Cube Round 1",
        subtitle:
          "Top 60 Participants will be proceeding to Round 2. Do take note of your position and report for Round 2 at 3.30PM",
      },
      snct_3x3x3_round2: {
        title: "3x3x3 Cube Round 2",
        subtitle:
          "Top 12 Participants will be proceeding to Round 2. Do take note of your position and report for Round 3 (Final) at 4:50 PM",
      },
      snct_3x3x3_round3: { title: "3x3x3 Cube Round 3" },
      snct_4x4x4_round1: {
        title: "4x4x4 Cube Round 1",
        subtitle:
          "Top 12 Participants will be proceeding to Round 2. Do take note of your position and report for Round 2 (Final) at 4:10 PM",
      },
      snct_4x4x4_round2: { title: "4x4x4 Cube Round 2" },
      snct_pyraminx_round1: {
        title: "Pyraminx Cube Round 1",
        subtitle:
          "Top 12 Participants will be proceeding to Round 2. Do take note of your position and report for Round 2 (Final) at 4:10 PM",
      },
      snct_pyraminx_round2: "Pyraminx Cube Round 2",
    },
    INDIVIDUAL: {
      primary: {
        title: "Individual - Primary",
        subtitle:
          "Championship Points are calculated based on Round 1 Timings.",
      },
      secondary: {
        title: "Individual - Secondary",
        subtitle:
          "Championship Points are calculated based on Round 1 Timings.",
      },
      overall: {
        title: "Individual - Overall",
        subtitle:
          "Championship Points are calculated based on Round 1 Timings.",
      },
    },
    LEVEL: {
      lower_primary: {
        snct_2x2x2_round1: {
          title: "Lower Primary - 2x2x2 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_3x3x3_round1: {
          title: "Lower Primary - 3x3x3 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_4x4x4_round1: {
          title: "Lower Primary - 4x4x4 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_pyraminx_round1: {
          title: "Lower Primary - Pyraminx Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
      },
      upper_primary: {
        snct_2x2x2_round1: {
          title: "Upper Primary - 2x2x2 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_3x3x3_round1: {
          title: "Upper Primary - 3x3x3 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_4x4x4_round1: {
          title: "Upper Primary - 4x4x4 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_pyraminx_round1: {
          title: "Upper Primary - Pyraminx Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
      },
      lower_secondary: {
        snct_2x2x2_round1: {
          title: "Lower Secondary - 2x2x2 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_3x3x3_round1: {
          title: "Lower Secondary - 3x3x3 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_4x4x4_round1: {
          title: "Lower Secondary - 4x4x4 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_pyraminx_round1: {
          title: "Lower Secondary - Pyraminx Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
      },
      upper_secondary: {
        snct_2x2x2_round1: {
          title: "Upper Secondary - 2x2x2 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_3x3x3_round1: {
          title: "Upper Secondary - 3x3x3 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_4x4x4_round1: {
          title: "Upper Secondary - 4x4x4 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_pyraminx_round1: {
          title: "Upper Secondary - Pyraminx Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
      },
      primary: {
        snct_2x2x2_round1: {
          title: "Primary - 2x2x2 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_3x3x3_round1: {
          title: "Primary - 3x3x3 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_4x4x4_round1: {
          title: "Primary - 4x4x4 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_pyraminx_round1: {
          title: "Primary - Pyraminx Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
      },
      secondary: {
        snct_2x2x2_round1: {
          title: "Secondary - 2x2x2 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_3x3x3_round1: {
          title: "Secondary - 3x3x3 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_4x4x4_round1: {
          title: "Secondary - 4x4x4 Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
        snct_pyraminx_round1: {
          title: "Secondary - Pyraminx Cube",
          subtitle: "Results are derived from Round 1 Timings.",
        },
      },
    },
    SCHOOL: {
      snct_school_qual: {
        title: "School - Qualifying",
        subtitle:
          "Results are derived from 3x3x3 Round 1 Timings. School Points are derived from Top 3 Participants of each School. Top 3 particiapnts of Top 8 schools can proceed to Special Round.",
      },
      snct_school_ovr: { title: "School - Overall" },
    },
  },
  TIMINGS: [
    {
      id: 1,
      eid: "snct_3x3x3",
      rid: "snct_3x3x3_round1",
      event: "3x3x3",
      levelId: "overall",
      round: 1,
      isFinalRound: false,
      startTime: new Date("2024-03-10T09:10:00"),
      endTime: new Date("2024-03-10T10:40:00"),
      icon: <Event3x3x3 />,
    },
    {
      id: 2,
      eid: "snct_4x4x4",
      rid: "snct_4x4x4_round1",
      event: "4x4x4",
      levelId: "overall",
      round: 1,
      isFinalRound: false,
      startTime: new Date("2024-03-10T10:40:00"),
      endTime: new Date("2024-03-10T12:00:00"),
      icon: <Event4x4x4 />,
    },
    {
      id: 3,
      eid: "snct_2x2x2",
      rid: "snct_2x2x2_round1",
      event: "2x2x2",
      levelId: "overall",
      round: 1,
      isFinalRound: false,
      startTime: new Date("2024-03-10T12:00:00"),
      endTime: new Date("2024-03-10T12:40:00"),
      icon: <Event2x2x2 />,
    },
    {
      id: 4,
      eid: "snct_pyraminx",
      rid: "snct_pyraminx_round1",
      event: "Pyraminx",
      levelId: "overall",
      round: 1,
      isFinalRound: false,
      startTime: new Date("2024-03-10T14:00:00"),
      endTime: new Date("2024-03-10T14:50:00"),
      icon: <EventPyraminx />,
    },
    {
      id: 5,
      eid: "snct_3x3x3",
      rid: "snct_3x3x3_round2",
      event: "3x3x3",
      levelId: "overall",
      round: 2,
      isFinalRound: false,
      startTime: new Date("2024-03-10T14:50:00"),
      endTime: new Date("2024-03-10T15:15:00"),
      icon: <Event3x3x3 />,
    },
    {
      id: 6,
      eid: "snct_4x4x4",
      rid: "snct_4x4x4_round2",
      event: "4x4x4",
      levelId: "overall",
      round: 2,
      isFinalRound: true,
      startTime: new Date("2024-03-10T15:05:00"),
      endTime: new Date("2024-03-10T15:25:00"),
      icon: <Event4x4x4 />,
    },
    {
      id: 7,
      eid: "snct_pyraminx",
      rid: "snct_pyraminx_round2",
      event: "Pyraminx",
      levelId: "overall",
      round: 2,
      isFinalRound: true,
      startTime: new Date("2024-03-10T15:25:00"),
      endTime: new Date("2024-03-10T15:40:00"),
      icon: <EventPyraminx />,
    },
    {
      id: 8,
      eid: "snct_2x2x2",
      rid: "snct_2x2x2_round2",
      event: "2x2x2",
      levelId: "overall",
      round: 2,
      isFinalRound: true,
      startTime: new Date("2024-03-10T15:40:00"),
      endTime: new Date("2024-03-10T15:55:00"),
      icon: <Event2x2x2 />,
    },
    {
      id: 9,
      eid: "snct_school",
      rid: "snct_school_qual",
      event: "3x3x3",
      round: "School Category",
      isFinalRound: true,
      startTime: new Date("2024-03-10T16:00:00"),
      endTime: new Date("2024-03-10T17:00:00"),
      icon: <EventSchool />,
    },
    {
      id: 10,
      eid: "snct_3x3x3",
      rid: "snct_3x3x3_round3",
      event: "3x3x3",
      levelId: "overall",
      round: 3,
      isFinalRound: true,
      startTime: new Date("2024-03-10T17:00:00"),
      endTime: new Date("2024-03-10T18:00:00"),
      icon: <Event3x3x3 />,
    },
  ],
};
