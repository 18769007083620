import { useTheme } from "@emotion/react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatTiming } from "../../utils";

const TableCategory = ({ participants, isMobile, top, handleClickOpen }) => {
  const theme = useTheme();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center">#</TableCell>
          <TableCell align="left">Name</TableCell>
          {isMobile ? null : (
            <>
              <TableCell align="left">Age</TableCell>
              <TableCell align="left">School</TableCell>
              <TableCell align="center">1</TableCell>
              <TableCell align="center">2</TableCell>
              <TableCell align="center">3</TableCell>
              <TableCell align="center">4</TableCell>
              <TableCell align="center">5</TableCell>
            </>
          )}
          <TableCell align="center">Average</TableCell>
          <TableCell align="center">Best</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {participants?.map((row) => (
          <TableRow
            hover
            onClick={() => handleClickOpen(row)}
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              align="center"
              component="th"
              scope="row"
              style={
                row?.position <= top
                  ? {
                      background: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText,
                    }
                  : {}
              }
            >
              <Typography variant="caption2">{row?.position}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="caption2">{row?.name}</Typography>
            </TableCell>
            {isMobile ? null : (
              <>
                <TableCell align="left">
                  <Typography variant="caption2">{row?.age}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption2">{row?.school}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatTiming(row?.timing_1)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatTiming(row?.timing_2)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatTiming(row?.timing_3)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatTiming(row?.timing_4)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatTiming(row?.timing_5)}
                  </Typography>
                </TableCell>
              </>
            )}
            <TableCell align="center">
              <Typography variant="caption">
                {formatTiming(row?.timing_average)}
              </Typography>
            </TableCell>
            <TableCell align="center">
              {formatTiming(row?.timing_best)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableCategory;
