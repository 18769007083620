import { Box, Dialog, Stack, Typography } from "@mui/material";
const events = {
  snct_2x2x2_round1: "2x2x2 Cube",
  snct_3x3x3_round1: "3x3x3 Cube",
  snct_4x4x4_round1: "4x4x4 Cube",
  snct_pyraminx_round1: "Pyraminx Cube",
};

const DialogResult = (props) => {
  const { onClose, result, open } = props;
  console.log(result);
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ p: 5 }} minWidth={300}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="subtitle1">Position</Typography>
            <Typography variant="body1">{result.position}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">Name</Typography>
            <Typography variant="body1">{result.name}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">Age</Typography>
            <Typography variant="body1">{result.age}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">School</Typography>
            <Typography variant="body1">{result.school}</Typography>
          </Box>
          {(result.timing_1 ||
            result.timing_2 ||
            result.timing_3 ||
            result.timing_4 ||
            result.timing_5) && (
            <Box>
              <Typography variant="subtitle1">Attempts</Typography>
              <Typography variant="body1">
                {result.timing_1}, {result.timing_2}, {result.timing_3},{" "}
                {result.timing_4}, {result.timing_5}
              </Typography>
            </Box>
          )}
          {result.points && (
            <Box>
              <Typography variant="subtitle1">Attempts</Typography>
              {result.points.map((e, i) => (
                <Typography variant="body1" key={e.rid}>
                  {`${e.championship_points} (${events[e.rid]})`}
                </Typography>
              ))}
            </Box>
          )}

          {result.timing_average && (
            <Box>
              <Typography variant="subtitle1">Average</Typography>
              <Typography variant="body1">{result.timing_average}</Typography>
            </Box>
          )}
          {!result.championship_points && !result.total && (
            <Box>
              <Typography variant="subtitle1">Best</Typography>
              <Typography variant="body1">{result.timing_best}</Typography>
            </Box>
          )}
          {result.championship_points && (
            <Box>
              <Typography variant="subtitle1">Points</Typography>
              <Typography variant="body1">
                {result.championship_points}
              </Typography>
            </Box>
          )}
          {result.total && (
            <Box>
              <Typography variant="subtitle1">Total Points</Typography>
              <Typography variant="body1">{result.total}</Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default DialogResult;
