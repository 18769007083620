import {
  InputAdornment,
  Paper,
  Stack,
  TableContainer,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Scrollbar } from "../scrollbar";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { supabase } from "../../config";
import { useLocation } from "react-router-dom";
import { constants } from "../../constants";
import TableCategory from "../tables/table-category";
import TableIndividual from "../tables/table-individual";
import TableSchool from "../tables/table-school";
import DialogCategory from "../dialogs/dialog-result";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useTheme } from "@emotion/react";

const ParticipantsList = () => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [top, setTop] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [participants, setParticipants] = useState([]);
  const [schools, setSchools] = useState([]);
  const queryRef = useRef(null);
  const [filters, setFilters] = useState({
    query: "",
  });
  const [header, setHeader] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get("event");
  const roundId = queryParams.get("round");
  const levelId = queryParams.get("level");
  const theme = useTheme();

  const handleClickOpen = (result) => {
    if (isMobile) {
      setSelectedRow(result);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchSchoolParticipants = async () => {
      try {
        const { data, error } = await supabase
          .from("live_timings")
          .select("*")
          .eq("eid", eventId)
          .eq("rid", roundId);

        const result = data.reduce((acc, participant) => {
          const existingSchool = acc.find(
            (item) => item.participants[0].school === participant.school
          );

          if (existingSchool) {
            existingSchool.participants.push({
              ...participant,
              position: existingSchool.participants.length + 1,
            });
          } else {
            acc.push({
              school: participant.school,
              participants: [participant],
            });
          }

          return acc;
        }, []);

        // Calculate the total points based on the scores of the top 3 participants for each school
        result.forEach((school) => {
          school.participants.sort(
            (a, b) => b.championship_points - a.championship_points
          );

          // Add the position of each participant within their respective school
          school.participants.forEach((participant, index) => {
            participant.positionInSchool = index + 1;
          });

          // Calculate the total points based on the scores of the top 3 participants
          const top3Points = school.participants
            .slice(0, 3)
            .reduce((total, participant, index) => {
              // participant.top3Position = index + 1;
              return total + parseFloat(participant.championship_points);
            }, 0);

          school.totalPoints = top3Points;
        });

        // Sort schools based on total points
        result.sort((a, b) => b.totalPoints - a.totalPoints);

        const topSchools =
          roundId === "snct_school_ovr" ? result.slice(0, 8) : result;

        setSchools(topSchools);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchIndividualParticipants = async () => {
      try {
        const { data, error } = await supabase
          .from("live_timings")
          .select("*")
          .in("rid", [
            "snct_2x2x2_round1",
            "snct_3x3x3_round1",
            "snct_4x4x4_round1",
            "snct_pyraminx_round1",
          ])
          .ilike(
            levelId === "primary" || levelId === "secondary" ? "level" : "",
            levelId === "primary" || levelId === "secondary"
              ? `%${levelId}%`
              : ""
          );
        const transformedArray = data.reduce((accumulator, current) => {
          const { name, age, school, rid, championship_points } = current;

          if (!accumulator[name]) {
            accumulator[name] = { name, age, school, points: [], total: "0" };
          }

          accumulator[name].points.push({
            rid,
            championship_points,
          });
          accumulator[name].total =
            parseFloat(accumulator[name].total) +
            parseFloat(championship_points);
          return accumulator;
        }, {});

        // console.log(transformedArray);

        const resultArray = Object.values(transformedArray);

        const sortedResultArray = resultArray.sort((a, b) => b.total - a.total);

        const resultArrayWithIds = sortedResultArray.map(
          (participant, index) => ({
            ...participant,
            position: index + 1,
          })
        );

        setParticipants(resultArrayWithIds);
        if (error) {
          console.error("Error fetching data:", error.message);
        }
      } catch (err) {
        console.error("Error:", err.message);
      }
    };

    const fetchCategoryParticipants = async () => {
      try {
        const { data, error } = await supabase
          .from("live_timings")
          .select("*")
          // .eq("eid", eventId)
          .eq("rid", roundId)
          .ilike("level", `%${levelId === "overall" ? "" : levelId}%`);

        if (error) {
          console.error("Error fetching data:", error.message);
        } else {
          const sortedData = data.slice().sort((a, b) => {
            const aTiming = parseFloat(a.timing_average);
            const bTiming = parseFloat(b.timing_average);

            if (isNaN(aTiming) || a.timing_average === "DNF") {
              return 1;
            }

            if (isNaN(bTiming) || b.timing_average === "DNF") {
              return -1;
            }

            return aTiming - bTiming;
          });

          const participantsWithPosition = sortedData.map(
            (participant, index) => ({
              ...participant,
              position: index + 1,
            })
          );
          // console.log(participantsWithPosition);
          setParticipants(participantsWithPosition || []);
        }
      } catch (err) {
        console.error("Error:", err.message);
      }
    };

    if (roundId && levelId) {
      fetchCategoryParticipants();
    }

    if (!roundId && levelId) {
      fetchIndividualParticipants();
    }

    if (roundId && !levelId) {
      fetchSchoolParticipants();
    }
  }, [eventId, roundId, levelId]);

  useEffect(() => {
    const event = constants.EVENTS.find((event) => event.eid === eventId);
    if (event) {
      const round = event.tabs.find((round) => round.rid === roundId);
      if (round) {
        setTop(round.top);
      }
    }
  }, [eventId, roundId]);

  const handleQueryChange = (event) => {
    event.preventDefault();
    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  };

  const applyFilters = (participants, filters) =>
    participants.filter((participant) => {
      if (filters.query) {
        let queryMatched = false;
        const properties = ["name", "school"];

        properties.forEach((property) => {
          if (
            participant[property]
              .toLowerCase()
              .includes(filters.query.toLowerCase())
          ) {
            queryMatched = true;
          }
        });

        if (!queryMatched) {
          return false;
        }
      }
      return true;
    });

  const handleEventName = () => {
    if (!roundId && levelId) {
      setHeader(constants.LABELS.INDIVIDUAL[levelId]);
    }

    if (roundId && !levelId) {
      setHeader(constants.LABELS.SCHOOL[roundId]);
    }

    if (roundId && levelId) {
      if (eventId === "snct_level") {
        const replaceLevelId = levelId.replace(/ /g, "_");
        setHeader(constants.LABELS.LEVEL[replaceLevelId][roundId]);
      } else {
        setHeader(constants.LABELS.CATEGORY[roundId]);
      }
    }
  };

  useEffect(() => {
    handleEventName();
  }, [eventId, roundId, levelId]);

  const filteredParticipants = applyFilters(participants, filters);

  return (
    <>
      {header && (
        <Box mb={3}>
          {
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">{header.title}</Typography>
              {roundId === "snct_school_qual" && (
                <Box display="flex" alignItems="center">
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 20, fill: theme.palette.secondary.main }}
                  />
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    - Special Round
                  </Typography>
                </Box>
              )}
            </Box>
          }
          <Typography variant="body2">{header?.subtitle}</Typography>
        </Box>
      )}
      {eventId !== "snct_school" && (
        <TableContainer component={Paper} sx={{ maxWidth: "100vw" }}>
          <Box
            // component="form"
            onChange={handleQueryChange}
            sx={{
              flexGrow: 1,
              m: 1.5,
            }}
          >
            <TextField
              defaultValue=""
              fullWidth
              inputProps={{ ref: queryRef }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              placeholder="Search participant"
            />
          </Box>
          {filteredParticipants && (
            <Scrollbar>
              {!roundId && levelId && (
                <TableIndividual
                  participants={filteredParticipants}
                  isMobile={isMobile}
                  top={top}
                  handleClickOpen={handleClickOpen}
                />
              )}
              {roundId && levelId && (
                <TableCategory
                  participants={filteredParticipants}
                  isMobile={isMobile}
                  top={top}
                  handleClickOpen={handleClickOpen}
                />
              )}
            </Scrollbar>
          )}
        </TableContainer>
      )}
      {schools && roundId && !levelId && (
        <Stack spacing={5}>
          {schools.map((school, index) => (
            <TableContainer component={Paper} sx={{ maxWidth: "100vw" }}>
              <TableSchool
                school={school}
                top={top}
                isMobile={isMobile}
                handleClickOpen={handleClickOpen}
                isOverall={roundId === "snct_school_ovr"}
                isQualified={roundId == "snct_school_qual" && index < 8}
                index={index}
              />
            </TableContainer>
          ))}
        </Stack>
      )}
      {selectedRow && (
        <DialogCategory
          result={selectedRow}
          open={open}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ParticipantsList;
