import { format, addMinutes, addSeconds } from "date-fns";
import moment from "moment";

const secondsToMMSSMS = (secondsValue) => {
  const duration = moment.duration(secondsValue, "seconds");
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format("m:ss:SS");

  return formattedDuration;
};

const roundToTwoDecimals = (value) => {
  const regex = /^\d+\.\d{3,}$/;
  if (regex.test(value)) {
    const roundedDownValue = Math.floor(value * 100) / 100;
    return roundedDownValue.toFixed(2);
  }
  return value;
};

export const formatTiming = (timing) => {
  if (timing !== "DNF") {
    if (timing > 60) {
      return secondsToMMSSMS(timing);
    }
    return roundToTwoDecimals(timing);
  }
  return timing;
};

export const formatPoints = (points) => {
  if (points) {
    return roundToTwoDecimals(points);
  }
};
