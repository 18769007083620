import { useTheme } from "@emotion/react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatPoints, roundToTwoDecimals } from "../../utils";

const TableIndividual = ({ participants, isMobile, top, handleClickOpen }) => {
  const theme = useTheme();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center">#</TableCell>
          <TableCell align="left">Name</TableCell>
          {isMobile ? null : (
            <>
              <TableCell align="left">Age</TableCell>
              <TableCell align="left">School</TableCell>
              <TableCell align="center">2x2</TableCell>
              <TableCell align="center">3x3</TableCell>
              <TableCell align="center">4x4</TableCell>
              <TableCell align="center">Pyraminx</TableCell>
            </>
          )}
          <TableCell align="center">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {participants?.map((row) => (
          <TableRow
            hover
            onClick={() => handleClickOpen(row, "")}
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              align="center"
              component="th"
              scope="row"
              style={
                row.position <= 3
                  ? {
                      background: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText,
                    }
                  : {}
              }
            >
              <Typography variant="caption2">{row?.position}</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="caption2">{row?.name}</Typography>
            </TableCell>
            {isMobile ? null : (
              <>
                <TableCell align="left">
                  <Typography variant="caption2">{row?.age}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption2">{row?.school}</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatPoints(
                      row?.points?.find(
                        (point) => point.rid === "snct_2x2x2_round1"
                      )?.championship_points
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatPoints(
                      row?.points?.find(
                        (point) => point.rid === "snct_3x3x3_round1"
                      )?.championship_points
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatPoints(
                      row?.points?.find(
                        (point) => point.rid === "snct_4x4x4_round1"
                      )?.championship_points
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="caption2">
                    {formatPoints(
                      row?.points?.find(
                        (point) => point.rid === "snct_pyraminx_round1"
                      )?.championship_points
                    )}
                  </Typography>
                </TableCell>
              </>
            )}
            <TableCell align="center">
              <Typography variant="caption2">
                {formatPoints(row?.total)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableIndividual;
