import {
  Box,
  Button,
  Dialog,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import MofunlandLiveLogo from "../../assets/icons/mofunland-live-logo.png";

const DialogWelcome = (props) => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box minWidth={300}>
        <Box sx={{ p: 5 }}>
          <Stack spacing={2}>
            <Box display="flex" justifyContent="center">
              <img
                src={MofunlandLiveLogo}
                alt="mofunland live logo"
                width={234}
              />
            </Box>
            <Typography variant="h6" alignSelf="center">
              Welcome to live.mofunland
            </Typography>
            <Typography variant="body2">
              This platform is still at its BETA phase whereby we are testing
              out key functions. As such, please be patient with us should you
              face any hiccups.
            </Typography>
            <Typography variant="body2">
              Do note the following,
              <ul style={{ marginLeft: "-14px" }}>
                <li>
                  Competition results will only be released after each round has
                  ended.
                </li>
                <li>
                  Participants are to be prepared for the round at least an hour
                  before the stipulated timing.
                </li>
                <li>
                  Each event round may run ahead or behind the stated time. As
                  such, the results may not be released right away.
                </li>
              </ul>
            </Typography>
            <Typography variant="body2">
              Should you have any queries, do feel free to approach any staff on
              duty.
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" mt={2} mb={1} mr={2}>
          <Button variant="contained" size="sm" onClick={handleClose}>
            Continue to site
          </Button>
        </Box>
        <Box textAlign="center" mb={1}>
          <Typography variant="caption">
            Copyright © Mofunland 2024. v1.0.8
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogWelcome;
