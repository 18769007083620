import { createSvgIcon } from "@mui/material";

export const EventPyraminx = createSvgIcon(
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.5972 9.31172C49.2206 8.2321 50.7789 8.2321 51.4022 9.31172L62.4466 28.4413C63.07 29.5209 62.2908 30.8704 61.0442 30.8704H38.9553C37.7086 30.8704 36.9295 29.5209 37.5528 28.4413L48.5972 9.31172Z"
      fill="#FF5630"
    />
    <path
      d="M51.4028 55.4656C50.7794 56.5452 49.2211 56.5452 48.5978 55.4656L37.5534 36.3361C36.93 35.2564 37.7092 33.9069 38.9558 33.9069H61.0447C62.2914 33.9069 63.0705 35.2564 62.4472 36.3361L51.4028 55.4656Z"
      fill="#9E280E"
    />
    <path
      d="M65.6021 36.4372C66.2254 35.3576 67.7837 35.3576 68.4071 36.4372L79.4515 55.5668C80.0748 56.6464 79.2957 57.9959 78.0491 57.9959H55.9601C54.7135 57.9959 53.9344 56.6464 54.5577 55.5668L65.6021 36.4372Z"
      fill="#FF5630"
    />
    <path
      d="M31.5933 36.4372C32.2167 35.3576 33.775 35.3576 34.3983 36.4372L45.4427 55.5668C46.0661 56.6464 45.2869 57.9959 44.0403 57.9959H21.9514C20.7047 57.9959 19.9256 56.6464 20.5489 55.5668L31.5933 36.4372Z"
      fill="#FF5630"
    />
    <path
      d="M48.5963 63.5627C49.2196 62.4831 50.7779 62.4831 51.4012 63.5627L62.4457 82.6923C63.069 83.7719 62.2898 85.1214 61.0432 85.1214H38.9543C37.7076 85.1214 36.9285 83.7719 37.5518 82.6923L48.5963 63.5627Z"
      fill="#FF5630"
    />
    <path
      d="M14.5885 63.5627C15.2118 62.4831 16.7701 62.4831 17.3934 63.5627L28.4379 82.6923C29.0612 83.7719 28.282 85.1214 27.0354 85.1214H4.94647C3.69983 85.1214 2.92068 83.7719 3.544 82.6923L14.5885 63.5627Z"
      fill="#FF5630"
    />
    <path
      d="M82.606 63.5627C83.2294 62.4831 84.7877 62.4831 85.411 63.5627L96.4554 82.6923C97.0788 83.7719 96.2996 85.1214 95.053 85.1214H72.9641C71.7174 85.1214 70.9383 83.7719 71.5616 82.6923L82.606 63.5627Z"
      fill="#FF5630"
    />
    <path
      d="M34.3979 82.5909C33.7746 83.6705 32.2163 83.6705 31.5929 82.5909L20.5485 63.4613C19.9252 62.3817 20.7043 61.0322 21.9509 61.0322H44.0399C45.2865 61.0322 46.0656 62.3817 45.4423 63.4613L34.3979 82.5909Z"
      fill="#9E280E"
    />
    <path
      d="M68.4067 82.5911C67.7833 83.6707 66.225 83.6707 65.6017 82.5911L54.5573 63.4616C53.9339 62.3819 54.7131 61.0324 55.9597 61.0324H78.0486C79.2953 61.0324 80.0744 62.3819 79.4511 63.4616L68.4067 82.5911Z"
      fill="#9E280E"
    />
  </svg>,
  "EventPyraminx"
);
